import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { FormError } from '../components/form-error';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { Button } from '../components/button';
import { Titles } from '../enums/titles.enum';
import { Labels } from '../enums/labels.enum';
import { FormErrorMessages } from '../enums/form-error-messages.enum';
import { IdentificationType } from '../enums/spanish.enum';
import { checkLength, removeSpecialCharacters } from '../utils/validate-utils';
import { IdType } from '../__api__/globalTypes';
import { Exceptions } from '../enums/exceptions.enum';
import { Link, useHistory } from 'react-router-dom';
import { provinces_provinces_cities } from '../__api__/provinces';
import logo from '../images/byller-logo.png';
import { useProvinces } from '../hooks/use-provinces';
import { Modal } from 'antd';
import {
  EMAIL_REGEX,
  PASSWORD_VALID_CHARACTERS_REGEX,
  STRONG_PASSWORD_REGEX,
} from '../constants';
import { registerUserStoreMutation, registerUserStoreMutationVariables } from '../__api__/registerUserStoreMutation';

const REGISTER_USER_STORE_MUTATION = gql`
  mutation registerUserStoreMutation($input: RegisterUserStoreInput!) {
    registerUserStore(input: $input) {
      customerId
      userId
    }
  }
`;

interface IRegisterStoreForm {
  lastName: string;
  name: string;
  identificationType: IdType;
  identificationNumber: string;
  cellPhone: string;
  cityId: string;
  provinceId: string;
  email: string;
  password: string;
  file: any | null;
}

export const RegisterNewUser = () => {

  const {
    register,
    watch,
    getValues,
    errors,
    handleSubmit,
    formState,
  } = useForm<IRegisterStoreForm>({
    mode: 'onChange',
    defaultValues: {
      identificationType: IdType.CITIZENSHIP_ID,
    },
  });

  const { data: provinces } = useProvinces();
  const history = useHistory();
  const onCompleted = (data: registerUserStoreMutation) => {
    const {
      registerUserStore: { customerId },
    } = data;
    if (customerId) {
      success();
    }
  };
  const [registerUserStoreMutation, { loading, error }] = useMutation<
    registerUserStoreMutation,
    registerUserStoreMutationVariables
  >(REGISTER_USER_STORE_MUTATION, {
    onCompleted,
  });  

  const onSubmit = async () => {
    if (!loading) {
      console.log(getValues());
      try {
        const { file, ...input } = getValues();
        // Verificar si el archivo existe
        const upload = file && file.length > 0 ? file[0] : null;
        
        await registerUserStoreMutation({
          variables: {
            input: {
              ...input,
              upload,
            },
          },
        });
      } catch (error) { }
    }
  };

  const getException = (error: any) => {
    const exception = Object.keys(Exceptions).find(
      (exception) => exception === error.message,
    );
    return exception ? Exceptions[exception as any] : error.message;
  }

  const idType = watch('identificationType');
  const provinceId: string = watch('provinceId');
  const provinceOptions = useMemo(() => provinces?.provinces || [], [
    provinces,
  ]);
  const citiesByProvinceId = (
    provinceId: string,
  ): provinces_provinces_cities[] => {
    return (
      provinceOptions.find((province: any) => province.id === provinceId)
        ?.cities || []
    );
  };
  const cities = citiesByProvinceId(provinceId) || [];
  const cityOptions = useMemo(
    () => (provinceId && cities.length ? cities : citiesByProvinceId('1')),
    [cities, provinceId],
  );

  const success = () => {
    Modal.success({
      content: 'Se envio tu registro exitosamente, en cuanto se apruebe tu registro te enviaremos un correo electronico o por WhatsApp para poder iniciar sesion',
      onOk: () => {
        history.push('/');
      },
    });
  };

  return (
    <div>
      {loading && (
        <div className="flex items-center justify-center h-screen">
          <span className="text-xl font-medium tracking-widest">
            {Labels.LOADING}
          </span>
        </div>
      )}
      <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
        <Helmet>
          <title>{Titles.REGISTER_STORE_PAGE}</title>
        </Helmet>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Link to="/">
            <img className="w-auto h-40 mx-auto" src={logo} alt="Workflow" />
          </Link>
          <h2 className="mt-1 text-2xl font-extrabold text-center text-gray-900">
            {Labels.CUSTOMER_REGISTER}
          </h2>
        </div>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="name" className="label">
                  {Labels.NAME}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: FormErrorMessages.REQUIRED_NAME,
                      minLength: 3,
                      maxLength: 50,
                      validate: {
                        isValidCharacters: (name) =>
                          /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(name) ||
                          FormErrorMessages.CHARACTERS,
                      },
                    })}
                    name="name"
                    minLength={3}
                    maxLength={50}
                    type="text"
                    className="input"
                  />
                  {errors.name?.message && (
                    <FormError errorMessage={errors.name?.message} />
                  )}
                  {(errors.name?.type === 'minLength' ||
                    errors.name?.type === 'maxLength') && (
                      <FormError errorMessage={FormErrorMessages.LENGTH} />
                    )}
                </div>
              </div>
              <div>
                <label htmlFor="lastName" className="label">
                  {Labels.LAST_NAME}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: FormErrorMessages.REQUIRED_LAST_NAME,
                      minLength: 3,
                      maxLength: 50,
                      validate: {
                        isValidCharacters: (lastName) =>
                          /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(lastName) ||
                          FormErrorMessages.CHARACTERS,
                      },
                    })}
                    name="lastName"
                    minLength={3}
                    maxLength={50}
                    type="text"
                    className="input"
                  />
                  {errors.lastName?.message && (
                    <FormError errorMessage={errors.lastName?.message} />
                  )}
                  {(errors.lastName?.type === 'minLength' ||
                    errors.lastName?.type === 'maxLength') && (
                      <FormError errorMessage={FormErrorMessages.LENGTH} />
                    )}
                </div>
              </div>
              <div>
                <label htmlFor="identificationType" className="label">
                  {Labels.ID_TYPE}
                </label>
                <div className="mt-1">
                  <select
                    name="identificationType"
                    ref={register({ required: true })}
                    className="select"
                  >
                    {Object.keys(IdType).map((idType) => (
                      <option key={idType} value={idType}>
                        {IdentificationType[idType as any]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="identificationNumber" className="label">
                  {Labels.ID_NUMBER}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: FormErrorMessages.REQUIRED_ID_NUMBER,
                      minLength: idType === IdType.RUC ? 13 : 10,
                      maxLength: idType === IdType.RUC ? 13 : 10,
                      validate: {
                        isValidCharacters: (identificationNumber) =>
                          /^[0-9]*$/.test(identificationNumber) ||
                          FormErrorMessages.CHARACTERS,
                      },
                    })}
                    name="identificationNumber"
                    type="tel"
                    pattern="[0-9]*"
                    minLength={idType === IdType.RUC ? 13 : 10}
                    maxLength={idType === IdType.RUC ? 13 : 10}
                    className="input"
                    onKeyDown={(e) => checkLength(e)}
                    onKeyUp={removeSpecialCharacters}
                  />
                  {errors.identificationNumber?.message && (
                    <FormError
                      errorMessage={errors.identificationNumber?.message}
                    />
                  )}
                  {(errors.identificationNumber?.type === 'minLength' ||
                    errors.identificationNumber?.type === 'maxLength') &&
                    [IdType.CITIZENSHIP_ID, IdType.PASSPORT].includes(
                      idType,
                    ) && (
                      <FormError errorMessage={FormErrorMessages.ID_LENGTH} />
                    )}
                  {(errors.identificationNumber?.type === 'minLength' ||
                    errors.identificationNumber?.type === 'maxLength') &&
                    idType === IdType.RUC && (
                      <FormError
                        errorMessage={FormErrorMessages.ID_LENGTH_RUC}
                      />
                    )}
                </div>
              </div>
              <div>
                <label htmlFor="cellPhone" className="label">
                  {Labels.CELL_PHONE}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: FormErrorMessages.REQUIRED_CELL_PHONE,
                      minLength: 10,
                      maxLength: 10,
                      validate: {
                        isValidCharacters: (cellPhone) =>
                          /^[0-9]*$/.test(cellPhone) ||
                          FormErrorMessages.CHARACTERS,
                      },
                    })}
                    name="cellPhone"
                    type="tel"
                    pattern="[0-9]*"
                    minLength={10}
                    maxLength={10}
                    className="input"
                    onKeyDown={(e) => checkLength(e)}
                    onKeyUp={removeSpecialCharacters}
                  />
                  {errors.cellPhone?.message && (
                    <FormError errorMessage={errors.cellPhone?.message} />
                  )}
                  {(errors.cellPhone?.type === 'minLength' ||
                    errors.cellPhone?.type === 'maxLength') && (
                      <FormError
                        errorMessage={FormErrorMessages.CELL_PHONE_LENGTH}
                      />
                    )}
                </div>
              </div>
              <div>
                <label htmlFor="provinceId" className="label">
                  {Labels.PROVINCE}
                </label>
                <div className="mt-1">
                  <select
                    name="provinceId"
                    ref={register({ required: true })}
                    className="select"
                  >
                    <option value="">{Labels.SELECTED_ONE}</option>
                    {provinceOptions.map((province: any) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="cityId" className="label">
                  {Labels.CITY}
                </label>
                <div className="mt-1">
                  <select
                    name="cityId"
                    ref={register({ required: true })}
                    className="select"
                  >
                    <option value="">{Labels.SELECTED_ONE}</option>
                    {cityOptions.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="email" className="label">
                  {Labels.EMAIL}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: true,
                      pattern: {
                        value: EMAIL_REGEX,
                        message: FormErrorMessages.VALID_EMAIL,
                      },
                    })}
                    name="email"
                    type="email"
                    className="input"
                  />
                  {errors.email?.message && (
                    <FormError errorMessage={errors.email?.message} />
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="password" className="label">
                  {Labels.PASSWORD}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: FormErrorMessages.REQUIRED_PASSWORD,
                      minLength: 8,
                      maxLength: 50,
                      validate: {
                        isValidCharacters: (password) =>
                          PASSWORD_VALID_CHARACTERS_REGEX.test(password) ||
                          FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                        strongPassword: (password) =>
                          STRONG_PASSWORD_REGEX.test(password) ||
                          FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                      },
                    })}
                    name="password"
                    minLength={8}
                    maxLength={50}
                    type="password"
                    className="input"
                  />
                  {errors.password?.message && (
                    <FormError errorMessage={errors.password?.message} />
                  )}
                  {(errors.password?.type === 'minLength' ||
                    errors.password?.type === 'maxLength') && (
                      <FormError
                        errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                      />
                    )}
                </div>
              </div>
             
              <Button
                canClick={formState.isValid}
                loading={loading}
                actionText={Labels.REGISTER}
              />
              {error && (
                <FormError errorMessage={getException(error)} />
              )}
            </form>
          </div>
          <Button
            canClick={true}
            loading={loading}
            bgColor='red'
            actionText={Labels.CANCEL}
            onClick={() => history.push('/login')}
          />
        </div>
      </div>
    </div>
  );
};